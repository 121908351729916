<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <circle style="fill:#FFC850;" cx="256" cy="256" r="167.724" />
    <circle style="fill:#FFDC64;" cx="256" cy="256" r="141.241" />
    <circle style="fill:#FFF082;" cx="326.621" cy="194.207" r="26.483" />
    <path
      style="fill:#FFC850;"
      d="M297.292,324.903c-53.248-8.926-95.412-52.643-102.105-106.218
	c-2.399-19.201-0.302-37.604,5.302-54.414c2.765-8.295-6.749-15.277-13.976-10.357c-37.044,25.216-59.683,69.978-52.904,119.52
	c7.507,54.859,52.37,98.762,107.358,105.262c41.547,4.911,79.521-10.851,105.175-38.305c5.998-6.419,0.7-16.962-7.962-15.49
	C325.155,327.113,311.435,327.273,297.292,324.903z"
    />
    <path
      style="fill:#E1A546;"
      d="M267.034,247.603v-59.386c23.339,2.653,39.724,13.368,39.724,23.094
	c0,6.094,4.94,11.034,11.034,11.034c6.094,0,11.034-4.94,11.034-11.034c0-23.31-26.389-41.977-61.793-45.236v-7.178
	c0-6.094-4.94-11.034-11.034-11.034c-6.094,0-11.034,4.94-11.034,11.034v7.178c-35.404,3.258-61.793,21.926-61.793,45.236
	c0,31.284,28.345,43.601,61.793,53.087v59.387c-23.339-2.653-39.724-13.368-39.724-23.094c0-6.094-4.94-11.034-11.034-11.034
	c-6.094,0-11.034,4.94-11.034,11.034c0,23.31,26.389,41.977,61.793,45.236v7.178c0,6.094,4.94,11.034,11.034,11.034
	c6.094,0,11.034-4.94,11.034-11.034v-7.178c35.404-3.258,61.793-21.926,61.793-45.236
	C328.828,269.406,300.483,257.089,267.034,247.603z M205.241,211.31c0-9.726,16.385-20.441,39.724-23.094v53.161
	C215.583,232.346,205.241,224.168,205.241,211.31z M267.034,323.784v-53.162c29.384,9.031,39.724,17.21,39.724,30.068
	C306.759,310.415,290.374,321.131,267.034,323.784z"
    />
    <path
      style="fill:#C7CFE2;"
      d="M256,0C182.97,0,114.781,30.52,66.244,84.237l4.29-30.023c0.69-4.828-2.664-9.302-7.491-9.992
	c-4.819-0.681-9.302,2.673-9.992,7.491l-8.828,61.793c-0.431,3.034,0.733,6.069,3.086,8.025c1.612,1.346,3.62,2.054,5.655,2.054
	c0.94,0,1.879-0.147,2.793-0.457l52.966-17.655c4.621-1.535,7.121-6.535,5.578-11.164c-1.535-4.621-6.508-7.129-11.164-5.578
	l-24.606,8.202C123.772,46.383,187.615,17.655,256,17.655c131.423,0,238.345,106.922,238.345,238.345S387.423,494.345,256,494.345
	c-4.879,0-8.828,3.948-8.828,8.828c0,4.879,3.948,8.828,8.828,8.828c141.155,0,256-114.845,256-256S397.155,0,256,0z"
    />
    <g>
      <path
        style="fill:#E4EAF8;"
        d="M220.698,509.5c-0.414,0-0.845-0.025-1.267-0.095c-3.034-0.431-6.044-0.914-9.035-1.465
		c-4.802-0.871-7.982-5.465-7.104-10.259c0.871-4.802,5.474-8.009,10.259-7.104c2.784,0.5,5.587,0.948,8.396,1.362
		c4.828,0.69,8.172,5.173,7.474,9.992C228.793,506.336,225.017,509.5,220.698,509.5z"
      />
      <path
        style="fill:#E4EAF8;"
        d="M178.836,499.715c-0.914,0-1.836-0.138-2.75-0.439c-5.63-1.845-11.224-3.905-16.638-6.112
		c-4.518-1.845-6.681-6.991-4.836-11.509c1.845-4.518,6.991-6.689,11.509-4.836c5.035,2.051,10.232,3.966,15.466,5.681
		c4.638,1.517,7.155,6.508,5.638,11.138C186.001,497.362,182.552,499.715,178.836,499.715z M132.104,478.759
		c-1.5,0-3.026-0.38-4.414-1.19c-5.06-2.931-10.103-6.094-15-9.414c-4.043-2.741-5.095-8.224-2.362-12.258
		c2.741-4.052,8.233-5.078,12.258-2.362c4.561,3.086,9.25,6.035,13.949,8.759c4.224,2.449,5.664,7.844,3.215,12.061
		C138.112,477.181,135.147,478.759,132.104,478.759z M90.724,448.629c-2.103,0-4.215-0.75-5.906-2.268
		c-4.362-3.932-8.647-8.061-12.742-12.285c-3.388-3.5-3.302-9.086,0.207-12.483c3.482-3.38,9.086-3.31,12.483,0.207
		c3.819,3.948,7.776,7.767,11.862,11.439c3.62,3.258,3.914,8.845,0.655,12.466C95.543,447.647,93.138,448.629,90.724,448.629z
		 M56.414,410.655c-2.724,0-5.405-1.25-7.129-3.612c-3.466-4.733-6.81-9.655-9.956-14.63c-2.603-4.12-1.37-9.578,2.75-12.181
		c4.138-2.595,9.578-1.362,12.181,2.75c2.931,4.647,6.026,9.198,9.267,13.63c2.879,3.939,2.026,9.457-1.906,12.337
		C60.043,410.103,58.225,410.655,56.414,410.655z M30.621,366.44c-3.371,0-6.578-1.931-8.052-5.19
		c-2.431-5.371-4.69-10.879-6.716-16.38c-1.698-4.569,0.638-9.646,5.216-11.336c4.561-1.725,9.655,0.647,11.336,5.216
		c1.897,5.112,3.991,10.232,6.25,15.224c2.008,4.448,0.034,9.673-4.405,11.681C33.069,366.189,31.837,366.44,30.621,366.44z
		 M14.517,317.845c-4.061,0-7.715-2.819-8.612-6.948c-1.267-5.784-2.336-11.647-3.172-17.414c-0.707-4.828,2.638-9.311,7.457-10.008
		c4.819-0.793,9.311,2.638,10.008,7.457c0.785,5.362,1.775,10.819,2.948,16.207c1.043,4.759-1.974,9.465-6.741,10.5
		C15.776,317.784,15.138,317.845,14.517,317.845z M8.836,266.983c-4.828,0-8.767-3.88-8.828-8.724L0,256
		c0-5.198,0.156-10.362,0.466-15.482c0.294-4.871,4.604-8.448,9.345-8.276c4.871,0.294,8.577,4.483,8.276,9.345
		c-0.294,4.776-0.431,9.577-0.431,14.413l0.009,2.051c0.061,4.871-3.844,8.871-8.724,8.931
		C8.905,266.983,8.871,266.983,8.836,266.983z M13.664,216c-0.577,0-1.164-0.052-1.75-0.172c-4.776-0.966-7.871-5.621-6.914-10.397
		c1.146-5.698,2.517-11.491,4.077-17.207c1.284-4.715,6.189-7.5,10.836-6.198c4.707,1.284,7.482,6.138,6.198,10.836
		c-1.44,5.284-2.716,10.638-3.801,16.052C21.465,213.103,17.784,216,13.664,216z"
      />
      <path
        style="fill:#E4EAF8;"
        d="M28.974,166.931c-1.164,0-2.354-0.233-3.482-0.715c-4.483-1.931-6.552-7.121-4.629-11.595
		c1.207-2.81,2.474-5.594,3.776-8.344c2.103-4.396,7.353-6.259,11.767-4.181c4.405,2.094,6.276,7.362,4.181,11.767
		c-1.216,2.543-2.379,5.121-3.5,7.724C35.647,164.931,32.388,166.931,28.974,166.931z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
